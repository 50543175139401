.victory-modal-overlay, .defeat-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background-image: url('../../assets/bg_2.png'); 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
}

.victory-modal, .defeat-modal {
    position: relative;
    border: solid #ffffff;
    background-color: #ffffff;
    padding: 40px;  /* Aumentado de 30px para 40px */
    border-radius: 15px;  /* Aumentado de 10px para 15px */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);  /* Aumento no raio do box-shadow */
    text-align: center;
    z-index: 3;
    animation: defeatBackgroundAnimation 3s infinite alternate;
}

.victory-gif, .defeat-gif {
    width: 100%;
    height: auto;  /* Garante que a altura se ajuste proporcionalmente */
    border-radius: 15px;  /* Aumentado de 10px para 15px */
    margin-bottom: 20px;  /* Aumentado de 15px para 20px */
}

.victory-title, .defeat-title {
    font-size: 3em;  /* Aumentado de 1.5em para 2em */
    font-weight: bold;
    margin-bottom: 20px;  /* Aumentado de 15px para 20px */
}

.victory-text, .defeat-text {
    font-size: 2.4em;  /* Aumentado de 1.5em para 2em */
    color: #000000;
    margin-bottom: 20px;  /* Aumentado de 15px para 20px */
    font-weight: bolder;
}

.logo-container {
    display: flex;
    justify-content: center;
    gap: 20px;  /* Aumentado de 10px para 20px */
    margin-bottom: 20px;  /* Ajuste para manter a consistência do espaçamento */
}

.frst-logo, .vesjobs-logo {
    width: 150px;  /* Aumentado de 100px para 150px */
}
