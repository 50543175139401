body {
  background-image: url('./assets/bg_2.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Poppins', sans-serif;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.animate {
  animation-duration: 0.5s;
  animation-name: animate-fade;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

.animate-slide {
  animation-duration: 0.5s;
  animation-name: animate-slide;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}


@keyframes animate-fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.animate.pop {
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(.26, .53, .74, 1.48);
}

@keyframes animate-pop {
  0% {
    opacity: 0;
    transform: scale(0.5, 0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}


.delay-1 {
  animation-delay: 0.6s;
}

.delay-2 {
  animation-delay: 0.7s;
}

.delay-3 {
  animation-delay: 0.8s;
}

.delay-3 {
  animation-delay: 2s;
}

.animate.slide {
  animation-name: animate-slide;
}

@keyframes animate-slide {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
}


.game-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

}


button {
  background: none;
  border: 1px solid #fff;
  padding: 8px 16px;
  border-radius: 4px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}

button:hover {
  background: #3c1421;
  color: #fff;
}

.right-panel,
.left-panel {
  background-color: rgba(24, 22, 26, 0.512);
  flex: 1;
  padding: 10px;
  border: 0.1px solid #ffffff08;
  border-radius: 5px;
}

.right-panel {
  max-height: 95vh;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.card-grid {
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(120px, 1fr));
  gap: 30px;
  width: 100%;
  max-height: 100vh;

}


.player-info-small {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  font-size: 2rem;
}

.player-info {
  margin-top: 5rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  place-content: center;
}

#score {
  font-size: 5rem;
  margin: 4rem;

}

span.text-sm {
  font-size: 3rem !important;
  color: #ffffff4c !important;
}

@media (min-width: 760px) {
  .game-container {
    flex-direction: row;
    overflow-x: hidden;

  }

  .right-panel {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 660px) {

  .right-panel {
    display: flex;
    align-items: normal;
  }
}

.animate-points {
  animation: bounce 0.5s ease-in-out;
  transition: opacity 0.5s ease-in-out;
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

.animate-points-fade {
  opacity: 0;
}

h2.animate-points-fade {
  visibility: hidden;
}

.rainbow-text {
  animation: rainbow 2s linear infinite;
}

@keyframes rainbow {
  0% {
    color: #ff0000;
  }

  16.666% {
    color: #ff8000;
  }

  33.333% {
    color: #ffff00;
  }

  50% {
    color: #80ff00;
  }

  66.666% {
    color: #00ff80;
  }

  83.333% {
    color: #0080ff;
  }

  100% {
    color: #8000ff;
  }
}

.logo {
  position: fixed;
  bottom: 0;
  margin-bottom: 5rem;
  opacity: 0.9;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .card-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
  }

  .card-grid .card {
    width: 90%;
    height: auto;
  }

  .game-container {
    width: 95%;
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  #score {
    font-size: 2.5rem;
    margin: 1rem 0;
  }

  span.text-sm {
    font-size: 1.5rem;
  }

  .player-info {
    flex-direction: column;
    margin-bottom: 0;
    font-size: 1.5rem;
  }


}