html {
  font-size: 62.5% !important; 
}
:root {
  --color-primary: #f4f1f0; /* OrangeRed */
  --color-secondary: #FF6347; /* Tomato */
  --color-tertiary: #FF7F50; /* Coral */
  --color-quaternary: #FF8C00; /* DarkOrange */
  --color-quinary: #FFA500; /* Orange */
}
.loading-screen-lead {
  position: fixed;
  top: 0;
  margin-left: -4rem;
  margin-top: -29rem;
  left: 50%;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
}

.loading-screen-lead {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;  
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;  
}


.text_shadows {
  text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary),
    9px 9px 0 var(--color-quaternary), 12px 12px 0 var(--color-quinary);
  font-family: 'Bungee', sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: calc(2rem + 5vw);
  text-align: center;
  margin: 0;
  color: var(--color-primary);
  animation: shadows 1.2s ease-in infinite, move 1.2s ease-in infinite;
  letter-spacing: 0.4rem;
}

@keyframes shadows {
  0%, 100% {
    text-shadow: none;
  }
  10% {
    text-shadow: 3px 3px 0 var(--color-secondary);
  }
  20% {
    text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary);
  }
  30% {
    text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px 0 var(--color-quaternary);
  }
  40%, 50%, 60% {
    text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px 0 var(--color-quaternary), 12px 12px 0 var(--color-quinary);
  }
  70% {
    text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary), 9px 9px 0 var(--color-quaternary);
  }
  80% {
    text-shadow: 3px 3px 0 var(--color-secondary), 6px 6px 0 var(--color-tertiary);
  }
  90% {
    text-shadow: 3px 3px 0 var(--color-secondary);
  }
}

@keyframes move {
  0%, 100% {
    transform: translate(0, 0);
  }
  40%, 50%, 60% {
    transform: translate(-12px, -12px);
  }
}

@keyframes popText {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(1.1); }
}

@keyframes shine-border {
  0% { box-shadow: 0 0 20px #fff; }
  50% { box-shadow: 0 0 40px #ff6600; }
  100% { box-shadow: 0 0 20px #fff; }
}

.lead-modal {
  background-color: white;
  padding: 4rem; 
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1rem; /* 10px */
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  width: 90%;
  animation: shine-border 3.5s infinite ease-in-out;
  max-width: 25rem; 
}

.lead-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-logo {
  margin-bottom: 0.5rem; 
  width: 10rem; 
  max-width: 100%; 
}


.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem; 
  margin-bottom: 5vh; 
  flex-direction: row !important;
}

.logo-part, .logo-plus {
  margin: 0 0.2rem;
  height: 4rem;
}

.logo-plus {
  width: 1rem; 
}

h2 {
  color: #333 !important;
  margin-bottom: 2rem; 
  font-size: 1.25rem; 
}

p {
  color: #666 !important;
  margin-bottom: 3rem; 
  font-size: 1.5rem; 
}
.question {
  text-align: start;
}
.form-group input, button {
  width: 100%; 
  padding: 1rem;
  border-radius: 0.5rem; 
  margin-bottom: 0.5rem; 
  font-size: 1.6rem;
}

.form-group input {
  border: 1px solid #E0E0E0;
  outline-color:  #ff660072;
  font-weight: 500;
  color: #828282;
}
.title-finish {
  font-size: 2.5rem !important;
  border-bottom: 1px solid #ff660072;
  padding: 0.8rem  1rem;
  border-radius: 4px;
  text-align: center;
  font-weight: 400;
  color: #000 !important;
}
 .duration-game, .pares-games {
  font-size: 2rem !important;
  text-align: center;
  font-weight: 400;
  color: #000 !important;
}
.form-group-checkbox {
  display: flex;
  align-items: center;
}

button {
  background-color: #FD7A49;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.5rem; 
  cursor: pointer;
}

button:hover {
  background-color: #fd7949e5;
}

.error-message, .message, .pin-button-message p {
  font-size: 1rem;
}

.message {
  font-size: 2rem; 
}

.pin-button-message {
  display: flex;
  color: #777;
  font-size: 1rem;
  flex-direction: column;
  align-items: center;
}

.pin-button-message p {
  font-size: 3rem; 
  font-weight: bolder;
}

.radio-group {
  display: flex;
  justify-content: start;
  margin-top: -1rem;
  padding-bottom: 1rem;
  margin-left: -1rem;
  align-items: center;
}
.label-radio {
  font-size: 1.5rem;
}

input[type=radio] {
  height: 2rem;
}

