.App {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #fff;
    padding: 2rem ;
}

.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #fff;
}

.lead-name {
    font-weight: bold;
    font-size: 3em;
}

.player-turns {
    font-size:3em;
}

.game-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    flex-grow: 1;
}
@keyframes shake {
    0%, 100% {
      transform: translateX(0) rotate(0);
    }
    10%, 90% {
      transform: translateX(-3px) rotate(-2deg);
    }
    30%, 70% {
      transform: translateX(3px) rotate(2deg);
    }
    50% {
      transform: translateX(0) rotate(0);
    }
  }
  
  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }
  
  .matched-animation {
    animation: shake 0.6s ease-in-out, pulse 0.4s ease-in-out 0.6s;
  }
  
  
.card-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    width: 100%;
    max-width: 800px;
}

footer {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #fff;
}

footer img {
    width: 80px;
}

.game-logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.game-logo-part {
    width: 200px; 
    height: auto;
    object-fit: cover;
}

.game-logo-plus {
    width: auto;
    height: 30px;
    object-fit: cover;
}


.loader {
    width: 50px;
    aspect-ratio: 1;
    display: flex;
    color: #FD7A49;
    background:
      linear-gradient(currentColor 0 0) right  /51% 100%,
      linear-gradient(currentColor 0 0) bottom /100% 51%;
    background-repeat: no-repeat;
    animation: l16-0 2s infinite linear .25s;
  }
  .loader::before{
    content: "";
    width: 50%;
    height: 50%;
    background: currentColor;
    animation: l16-1 .5s infinite linear;
  }
  @keyframes l16-0 {
    0%   ,12.49% {transform: rotate(0deg)}
    12.5%,37.49% {transform: rotate(90deg)}
    37.5%,62.49% {transform: rotate(180deg)}
    62.5%,87.49% {transform: rotate(270deg)}
    87.5%,100%   {transform: rotate(360deg)}
  }
  @keyframes l16-1 {
    0%      {transform: perspective(80px) rotate3d(-1,-1,0,0)}
    80%,100%{transform: perspective(80px) rotate3d(-1,-1,0,-180deg)}
  }

  