.card {
    position: relative;
    z-index: 1;
  }
  .card img {
    height: 100%;
    margin: 0;
    padding: 0;
    width: 100%; 
    object-fit: cover;
    border-radius:20px ;
    column-gap: 0px;
  }
    .card .front {
    transform: rotateY(90deg);
    transition: all ease-in 0.2s;
    position: absolute;
  }
  .flipped .front {
    transform: rotateY(0deg);
    transition-delay: 0.2s;
  }
    .card .back {
    transition: all ease-in 0.2s;
    transition-delay: 0.2s;
  }
  .card .back img {
    object-fit: contain !important;
  }
  .flipped .back {
    transform: rotateY(90deg);
    transition-delay: 0s;
  }