@keyframes shine-border {
    0% { box-shadow: 0 0 20px #fff; }
    50% { box-shadow: 0 0 40px #ff6600; }
    100% { box-shadow: 0 0 20px #fff; }
  }
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 2rem; 
    border-radius: 50px;
    text-align: center;
    width: 90rem; 
    margin-bottom: 5vh;
    padding-bottom: 12rem;
    animation: shine-border 3.5s infinite ease-in-out;
}

.logo-container-pin {
    display: grid;
    margin-top: 3rem; 
    margin-bottom: 5vh; 
}

 .logo-part-pin {
    margin: 0 0.5rem;
    height: 9rem; 
}

.logo-plus-pin {
    margin-top: 1rem;
    margin-bottom: 1rem;
    height: 4rem  !important; 
    margin-left: 12rem !important;
}

.cta-container {
    margin-top: 2rem;
}

.vamos-jogar {
    margin-bottom: 1rem;
    color: #333;
    font-weight: bolder;
    font-size: 6rem !important; 
}

.digite {
    margin-bottom: 2rem;
    color: #666;
    font-size: 4.5rem !important;
}

.validate-button {
    background-color: #FD7A49;
    color: white;
    padding: 2rem 3rem;
    border: none;
    border-radius:8px;
    cursor: pointer;
    width: 80%;
    font-size: 4rem; 
    font-weight: 700;
}

.pin-input {
    padding: 1rem;
    border: 1px solid #fff;
    border-radius: 0.5rem;
    font-size: 8rem;
    margin-top: 0.5rem;
    color: #ff4500;
}
.pin-display {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.pin-square {
    width: 14rem;
    height: 14rem;
    border: 2px solid #ccc;
    border-radius: 10px;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10rem; 
    background-color: #fff;
    color: #FD7A49;
    overflow: hidden;
}


.validate-button:hover {
    background-color: #ff4500;
}

.error-message {
    color: red;
    margin-bottom: 1rem; 
    font-size: 1.5rem;
}

.keypad-container {
    position: fixed;
    bottom: 0;
    margin-bottom: 10vh;
}
.keypad {
    display: flex;
    gap: 16px;
    background-color: rgb(255, 255, 255);
    opacity: 1px;
    border-radius: 10px;
    padding: 3rem 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    animation: shine-border 6.4s infinite ease-in-out;

}

.keypad-button {
    font-size: 6rem;
    border-radius: 10px;
}

.delete-icon {
    width: 60px;
    height: 50px;
    fill: #ffffff; 
    display: flex;
}
