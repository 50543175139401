.dashboard-container {
    padding: 20px;
    color: white;
    background-color: #2d3748;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dashboard-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: center;
}

.statistics {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 30px;
}

.stat {
    background-color: #4a5568;
    padding: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.stat-label, .stat-value {
    color: white;
    margin-bottom: 0.5rem;
}

.stat-label {
    font-size: 1.2rem;
    font-weight: bold;
}

.stat-value {
    font-size: 1.5rem;
}

.table-container {
    overflow-x: auto;
    max-height: 30vh;
    margin-bottom: 20px;
}

.leads-table {
    width: 100%;
    background-color: #2d3748;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.leads-table th, .leads-table td {
    padding: 12px;
    text-align: center;
    border-bottom: 1px solid #2d3748;
    color: white;
}

.leads-table th {
    background-color: #2d3748;
    font-weight: bold;
}

.leads-table tbody tr:nth-child(even) {
    background-color: #718096;
}

.leads-table tbody tr:hover {
    background-color: #4a5568;
}
